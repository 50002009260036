import axios from "@/helpers/axios";

const base_url = process.env.VUE_APP_API_BASEURL;
const adx_url = process.env.VUE_APP_BASEURL;

// async function list(params) {
//   return axios.get(`${base_url}products`, { params }).then((response) => {
//     return response.data;
//   });
// }
async function list(params) {
  return axios.get(`${adx_url}roles-and-permissions/products`, { params }).then((response) => {
    return response.data;
  });
}

async function product_with_tiers(params) {
  return axios
    .get(`${base_url}products-with-tiers`, { params })
    .then((response) => {
      return response.data;
    });
}

// countries
async function countries(params) {
  return axios.get(`${base_url}countries`, { params }).then((response) => {
    return response.data;
  });
}
// states
async function states(params) {
  return axios.get(`${base_url}states`, { params }).then((response) => {
    return response.data;
  });
}
// cities
async function cities(params) {
  return axios.get(`${base_url}cities`, { params }).then((response) => {
    return response.data;
  });
}

export default {
  list,
  product_with_tiers,
  countries,
  states,
  cities,
};
